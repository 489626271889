/* eslint-disable */
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import moment from 'moment-timezone'
import { isNull } from 'lodash-es'

export default {
  methods: {
    // @vuese
    canManage (list) {
      if (list !== undefined && list.length > 0) {
        if (list.includes(firebase.auth().currentUser.uid)) {
          return true
        }
      }
      const remoteConfig = firebase.remoteConfig()
      const value = remoteConfig.getString('admin_ids')
      const arr = JSON.parse(value)
      if (arr['list'].includes(firebase.auth().currentUser.uid)) {
        return true
      }
      return false
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date, format = 'DD/MM/YYYY H:mm') {
      if (isNull(date) || date === undefined) return 'Invalid date'
      return moment(moment.unix(date.seconds), 'YYYY-MM-DD').tz(process.env.VUE_APP_TIMEZONE).format(format)
    },
    setState (match, state) {
      const missing = Object.values(match).find(e => e === 'MISSING')
      if (missing) return 'error'
      return (state === 'INCOMPLETE_IMPORT') ? 'error' : 'success'
    },
    matchStateColor(state) {
      switch (state) {
        case 'ON_GOING':
          return 'orange'
        case 'APPROVED_BY_REFEREE':
          return 'primary'
        default:
          return 'grey lighten-2'
      }
    },
    eventColor (eventType) {
      const colors = [
        {event: 'matchStarted', color: 'teal lighten-1'},
        {event: 'matchEnded', color: 'red'},
        {event: 'periodEnded', color: 'teal lighten-1'},
        {event: 'yellowCard', color: 'yellow'},
        {event: 'twoMinuteSuspension', color: 'orange'},
        {event: 'redCard', color: 'red darken-1'},
        {event: 'disqualification', color: 'black'},
        {event: 'timeout', color: 'brown'},
        {event: 'goal', color: 'green darken-2'},
        {event: 'goal7meters', color: 'green darken-4'},
        {event: 'miss7meters', color: 'cyan darken-1'},
        {event: 'periodEnded', color: 'teal lighten-1'}
      ]
      try {
        const index = colors.find(element => element.event === eventType);
        return index.color
      }
      catch (error) {
        console.error(error)
      }
    },
    getClubLogo (id) {
      return `https://files.hsf.fo/clubs/${id}.webp`
    },
    getClubLogoByPath (path) {
      return path
    },
    stateColor (state) {
      switch (state) {
        case 'ON_GOING':
          return 'orange'
        case 'APPROVED_BY_REFEREE':
          return 'primary'
        default:
          return 'grey lighten-2'
      }
    },
    validateInput (input) {
      switch (input.value.length) {
        case 0:
          return []
        default :
          return input.value.reduce((a, item) => [...a, item.name], [] )
      }
    },
    filterObj (obj, include) {
      const result = {}
      let key = ''
      for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && this.filterByKey(include, key)) {
          result[key] = obj[key]
        }
      }
      return result
    },
    filterByKey (array, value) {
      return array.indexOf(value) !== -1
    },
  }
}
